export const en = {
  auth: {
    login: 'login',
    logout: 'Logout',
    rememberMe: 'Remember me',
    signIn: 'Sign in',
    email: 'Email',
    mail_massage: 'The input is not valid e-mail',
    mail_req_massage: 'Please enter your e-mail',
    password_placeholder: 'Enter your password',
    plaeaseEnterMail: 'Please enter your email then click the button below to request another one.',
    password: 'Password',
    forgetPassword: 'Forget password?',
    Forget_Password: 'Forget password',
    sammary: 'Please enter your email so that we can  send you a rest password email.',
    passwordMassage: 'Please enter your password',
    backToSignin: 'Back to sign in',
    sendMail: 'Send email',
    sorry505: 'The server encountered an internal error or misconfiguration and  was  unable to complete your request',
    resetSent: 'Reset code was sent successfully',
    confirmPassword: 'Confirm new password',
    restPass_placeholder: 'Confirm your password',
    confirm_massage: 'Please confirm your password',
    Reset: 'Reset',
    cancle: 'Cancel',
    CheckInpoxp1: 'Please check your inbox or spam folder at',
    CheckInpoxp2: 'For a link to reset your password .',
    GoToMail: 'Go to your mail',
    Ok: 'OK',
    oops: 'Oops This url is not valid anymore.',
    ResendMail: 'Resend mail',
    passwordmin: 'Password must be min 8 characters',
    passwordmax: 'Password must be max 20 characters',
    passwordexpresion: 'Password must be at least 8 characters including capital and small letters.',
    profile: 'Profile',
    personalInfo: 'Personal information',
    name: 'Name',
    nameMassage: 'Please enter your name',
    namePlaceholder: 'Enter your name',
    Security: 'Security',
    Account: 'Account',
    Change: 'Change',
    Deactivate: 'Deactivate',
    DeactivateAccount: 'Deactivate account',
    SaveChanges: 'Save changes',
    ChangePassword: 'Change password',
    NoCancel: 'No, Cancel',
    YesDeactivate: 'Yes, Deactivate',
    PasswordsChanged: 'Passwords changed successfully',
    AccountDeactivated: 'Account deactivated',
    YourAccountDeactivated:
      'Your account have be deactivated, Please be informed that you’ll never be able to login again or restore your account',
    Delete: 'Delete',
    AreYouSoure: 'Are you sure you want to delete this data ?',
    YesSave: 'Yes, Save',
    NoSave: 'No, don’t save',
    NewPassword: 'New password',
    Cancel: 'Cancel',
    EnterPassFor: 'Please enter your password for',
    TimedOut: 'You are being timed-out due to inactivity .Please choose to stay ',
    SinginOrLogout: 'Signed-in or log-out .',
    otherwise: 'Otherwise , you will be logged out automatically',
    minute: 'after 1 minute.',
    stay: 'Stay logged-in',
    inactivityMessage: 'You have been logged-out due to inactivity',
    incorrectOldPassword: 'Old password is incorrect',
    Imagemustsmallerthan5MB: 'Image must smaller than 5MB',
    YoucanonlyuploadJPGPNG: 'You can only upload JPG/PNG file',
    createAccount: 'Create account',
    aleadyHaveAccount: 'Aleady have an account ?',
    signUp: 'Sign up',
    profileUpdated: 'Profile updated successfully',
  },
  notifi: {
    MarkAll: 'Mark all reeded',
    NotifiContent: 'Welcome back to DigitalYard admin panel',
    fiveMin: '5 Mins ago',
    Delete: 'Delete',
  },
  home: {
    exportExcel: 'Export excel',
  },
  header: {
    Search: 'Search...',
    Editprofile: 'Edit profile',
    SUPERADMIN: 'SUPER admin',
    NOTIFICATIONS: 'Notifications',
    Seeall: 'See all',
  },
  errors: {
    Unauthorized: 'Unauthorized',
    sorry401: 'Sorry , your request could not be processed.',
    goToHome: 'Go to home page',
    sorry403: 'We are sorry , but you do not have access to this page or resource.',
    Access: 'Access Denied',
    notFound: 'Page not found',
    error_text: ' The page you’re looking for might have been removed, had its name changed or is temporarily unavailable.',
    serverError: 'Internal server error',
    sorry400: 'the request made is incorrect or corrupt',
  },
  MScreens: {
    LinkExpired: 'Link expired',
    plaeaseEnterMail: 'Please enter your email then click the button below to request another one.',
    email: 'Email',
    mail_massage: 'The input is not valid e-mail',
    mail_req_massage: 'Please enter your e-mail',
    ResendMail: 'Resend mail',
    oops: 'Oops This url Is Not Valid Anymore.',
    Ok: 'OK',
    PasswordReset: 'Passwords reset successfully',
    ResetPassword: 'Rest new password',
    NewPassword: 'New password',
    passwordMassage: 'Please enter your password',
    passwordmin: 'Password must be min 8 characters',
    passwordmax: 'Password must be max 20 characters',
    passwordexpresion: 'Password must be at least 8 characters including capital and small letters.',
    password_placeholder: 'Enter your password',
    confirmPassword: 'Confirm new password',
    confirm_massage: 'Please confirm your password',
    restPass_placeholder: 'Confirm your password',
    cancle: 'Cancel',
    resetSent: 'Reset code was sent successfully',
    Reset: 'Reset',
    CheckInpoxp1: 'Please check your inbox or spam folder at',
    CheckInpoxp2: 'For a link to reset your password .',
    backToSignin: 'Back to sign in',
  },
  settings: {
    PasswordsChanged: 'Passwords changed successfully',
    successfullyDeleted: 'successfully deleted',
    ChangePassword: 'change password',
    NewPassword: 'New password',
    passwordMassage: 'Please enter your password',
    passwordmin: 'Password must be min 8 characters',
    passwordmax: 'Password must be max 20 characters',
    passwordexpresion: 'Password expresion that requires one lower case letter, one upper case letter, one digit',
    password_placeholder: 'Enter your password',
    confirmPassword: 'Confirm new password',
    confirm_massage: 'Please confirm your password',
    restPass_placeholder: 'Confirm your password',
    logout: 'logout',
    AccountDeactivated: 'Account deactivated',
    YourAccountDeactivated:
      'Your account have be deactivated, Please be informed that you’ll never be able to login again or restore your account',
    YesSave: 'Yes, save',
    NoSave: 'No, don’t save',
    Cancel: 'Cancel',
    Delete: 'Delete',
    SaveChanges: 'Save changes',
    AreYouSoure: 'Do you want to save this data ?',
    signIn: 'Sign in',
    EnterPassFor: 'Please enter your password for',
    password: 'Password',
    passwordMassage: 'Please enter your password',
    passwordmin: 'Password must be min 8 characters',
    passwordmax: 'Password must be max 20 characters',
    passwordexpresion: 'Password expresion that requires one lower case letter, one upper case letter, one digit',
    password_placeholder: 'Enter your password',
  },
  setting: {
    Settings: 'Settings',
    Integratedappsmasterata: 'Integrated apps master data',
    GoogleRECAPTCHA: 'Google recaptcha',
    GoogleAnalyticsTrackingCode: 'Google analytics tracking code',
    FacebookAppID: 'Facebook app iD',
    FacebookAppSecret: 'Facebook app secret',
    LinkedInAppID: 'LinkedIn app iD',
    EmailedLinksSettings: 'Emailed links settings',
    Emailverification: 'Email verification',
    Enable: 'Enable',
    Emailverificationlinkexpirytimeminutes: 'Email verification link expiry time (minutes)',
    Mins: 'Mins',
    EmailNotifications: 'Email notifications',
    Resetpasslinkverificationlinkexpirytimeminutes: 'Reset pass link verification link expiry time (minutes)',
    Resendmailinterval: 'Resend mail interval',
    Tries: 'Tries',
    Xplacholder: 'xxxx-xxxx-xxxx',
  },
  Privileges: {
    AddRole: 'Add role',
    Newrole: 'New role',
    Personalinfo: 'Personal info',
    Rolename: 'Role name',
    ISACTIVE: 'IS active',
    Hidetranslations: 'Hide translations',
    Showtranslations: 'Show translations',
    TranslateIntoAr: 'Translate into “عربي“',
    TranslateIntoFr: 'Translate into “française“',
    Enable: 'Enable',
    Rolestatus: 'Role status',
    Privileges: 'Privileges',
    Privilege: 'Privilege',
    AddPrivileges: 'Add Privileges',
    SelectPrivileges: 'Select Privileges',
    PleaseselectPrivilegestoAssign: 'Please select Privileges to assign',
    AssignedPrivileges: 'Assigned Privileges',
    Admins: 'Admins',
    AddAdmins: 'Add admins',
    SelectAdmins: 'Select admins',
    Admin: 'Admin',
    AddAdmin: 'Add admin',
    SelectAdmin: 'Select admin',
    PleaseselectAdminstoAssign: 'Please select admins to assign',
    deletedSuccessfully: 'Selected roles deleted successfully',
    editedSuccessfully: 'Selected role edited successfully',
    addedSuccessfully: 'Role added successfully',
  },
  currency: {
    Currencyname: 'Currency name',
    Addcurrency: 'Add currency',
    Newcurrency: 'New currency',
    currencyinfo: 'Currency info',
  },
  users: {
    USERS: 'Users',
    Name: 'Name',
    Age: 'Age',
    Email: 'Email',
    Address: 'Address',
    Admins: 'Admins',
    UpdateAdmins: 'Update admins',
    Adduser: 'Add admin',
    Personalinfo: 'Personal info',
    Rules: 'Roles',
    Addrule: 'Add role',
    Selectrule: 'Select role',
    PleaseselectruletoAssign: 'Please select role to Assign',
    Assignedrules: 'Assigned roles',
    deletedSuccessfully: 'Selected users deleted successfully',
    editedSuccessfully: 'Selected user edited successfully',
    addedSuccessfully: 'User added successfully',
  },
  grid: {
    New: 'New ',
    goTo: 'Go to',
    gridSearch: 'Search',
    reset: 'Reset',
    sureToCancel: 'Sure to cancel?',
    no: 'No',
  },
  buttons: {
    Close: 'Close',
    SaveNew: 'Save & New',
    Save: 'Save',
    Cancel: 'Cancel',
    UpdateNew: 'Update & New',
    Update: 'Update',
    Duplicate: 'Duplicate',
    Delete: 'Delete',
    View: 'View',
    Archive: 'Archive',
    Edit: 'Edit',
    SaveAddConnectorsDetails: 'Save & Add Connectors Details',
    AddConnectorsDetails: 'Add Connectors',
    ConnectorsDetails: 'Connectors Details',
    ChargerDetails: 'Charger Details',
    confirm: 'Are you sure you want to ',
    selectToEdit: 'Please select one row to edit',
    selectToDelete: 'Please select one row or more to delete',
    change: 'Change',
    remove: 'Remove',
    Send: 'Send',
    Book: 'Book',
    qu: '?',
    selectAll: 'Select All',
    deleteTheRest: 'Delete the rest',
    add: 'Add',
    YesCancel: 'Yes, Cancel',
    NoDontCancel: 'No, Don’t Cancel',
    rotate: 'Rotate',
    crop: 'Crop',
  },
  inputs: {
    inputrequired: 'Input required',
    PleaseinputyourFirstname: 'Please enter your first name',
    PleaseinputyourMiddlename: 'Please enter your middle name',
    PleaseinputyourLastName: 'Please enter your last name',
    Pleaseinputyourphonenumber: 'Please enter your phone number',
    Firstname: 'First name',
    Middlename: 'Middle name',
    LastName: 'Last name',
    PhoneNumber: 'Phone number',
    phonemin: "Phone can't be less than 8 numbers",
    phonemax: "Phone can't be more than 11 numbers",
    phone: 'phone',
    Email: 'E-mail',
    TheinputisnotvalidEmail: 'The input is not valid e-mail',
    PleaseinputyourEmail: 'Please enter your e-mail',
    EmailAlreadyExists: 'Email already exists',
    Firstnamemustcharacters: 'Name must be min 3 characters',
    namecantacceptspace: "Name dosen't accept space",
    Namecanacceptspecialcharacters: "Name can't accept  special characters",
    Namecanacceptnumbers: "Name can't accept numbers",
    passwordDoesnotMatch: 'Password and confirmation does not match',
  },
  masterData: {
    basicInfo: 'Basic info',
    label: 'Master data',
    add: 'Add',
    name: 'Name',
    email: 'Email',
    value: 'Value',
    isActive: 'Is active',
    active: 'Active',
    inActive: 'Inactive',
    translate: 'Translation',
    hideTranslations: 'Hide translations',
    nameMaxLength: 'Name length must not exceed 60 character',
    nameTranslationMaxLength: 'Name translation max length must not exceed 60 character',
    nameMinLength: 'Name minimum length is 3 characters',
    nameTranslationMinLength: 'Name translation minimum length is 3 characters',
    namePlaceholder: 'Enter name here',
    valuePlaceholder: "Enter value ex:'25'",
    nameTranslationPlaceholder: 'Enter name translation here',
    deletedSuccessfully: 'Selected data deleted successfully',
    editedSuccessfully: 'Selected data edited successfully',
    addedSuccessfully: 'Data added successfully',
    theFollowingData: 'The following data ',
    inUseData: " are in use and can't be deleted",
    inUseActivated: " is in use and can't be deactivated",
    phone: 'Phone',
    Ban: 'Ban',
    Banned: 'Banned',
  },
  countries: {
    basicInfo: 'Basic info',
    label: 'Country',
    deletedSuccessfully: 'Selected countries deleted successfully',
    editedSuccessfully: 'Selected country edited successfully',
    addedSuccessfully: 'Country added successfully',
    nullFlag: 'Please select an image',
    countryCurrency: "Country's currency",
    currencyPlaceholder: "Select country's currency",
    countryCodePlaceholder: 'Enter country phone code, ex: +20',
    countryCode: 'Country code',
  },
  governorates: {
    basicInfo: 'Basic info',
    label: 'Governorate',
    deletedSuccessfully: 'Selected governorates deleted successfully',
    editedSuccessfully: 'Selected governorate edited successfully',
    addedSuccessfully: 'Governorate added successfully',
  },
  schools: {
    SchoolName: 'School name',
    Country: 'Country',
    AddSchool: 'Add school',
    State: 'State',
    selectState: 'Select a state',
    Fax: 'Fax',
    AddressLine1: 'Address line 1',
    AddressLine2: 'Address line 2',
    PaymentMethod: 'Payment method',
    Website: 'Website',
    SchoolEmail: 'School e-mail',
    AllowedStudents: 'Allowed students',
    ContactPersonName: 'Contact Person name',
    PleaseEngerName: 'Please enter Your name',
    Position: 'Position',
    enterPosition: 'Please enter your position',
    email: 'Email',
    enterEmail: 'Please enter your email',
    Country: 'Country',
    selectCountery: 'Select a country',
    addContactPersonData: 'Add contact',
    invalidURL: 'Please enter a valid url',
    schoolData: 'School data',
    contactPersonData: 'Contact person Data',
    contactWorkPhone: 'Work Phone',
    contactPersonalPhone: 'Personal Phone',
    addContactModalTitle: 'Add contact person',
  },
  sideBar: {
    Admins: 'Admins',
    Roles: 'Roles',
    Schools: 'Schools',
    Stations: 'Stations',
    MasterData: 'Master data',
    Setting: 'Settings',
    countries: 'Countries',
    Positions: 'Positions',
    PaymentMethods: 'Payment methods',
    Currency: 'Currencies',
    governorates: 'Governorates',
    Sectors: 'Sectors',
    Teams: 'Teams',
    Activities: 'Activities',
    SickReport: 'Sick report',
    Types: 'Car types',
    Brands: 'Car brands',
    ChargerManagement: 'Charger stations',
    cartype: 'Car types',
    carbrand: 'Car brands',
    batteryrange: 'Battery range',
    Users: 'Customers',
    Contactusrequests: 'Contact Us',
    Cardrequests: 'Cards',
    Chargerspricingsettings: 'Chargers pricing',
    Security: 'Security',
    Requests: 'Requests',
    ChargersManagement: 'Chargers',
  },
  payment: {
    PaymentTypes: 'Payment types',
    PaymentHistory: 'Payment history',
    Chooseyourpaymentplan: 'Choose your payment plan that you will follow:',
    SelectPaymenttype: 'Select Payment type',
    Features: 'Features:',
    CurrentStudentsNumber: 'Current students number:',
    student: 'Student',
    Changesby: 'Changes by :',
    EffectiveDate: 'Effective date',
    LastChanges: 'Last changes :',
    seemore: 'See more',
    CurrentTotalCost: 'Current total cost :',
    perStu: 'L.E per 1 Stu.',
    NumberofdaysuntileveryPayment: 'Number of days until every payment.',
    InspecificDayeverymonths: 'In specific day every N. of months.',
    days: 'Days',
    Mon: 'Mon.',
  },
  Stations: {
    Newstation: 'New Station',
    ViewStation: 'View Station',
    StationsName: 'Stations Name',
    NofChargers: 'N. of Chargers',
    ChoutofService: 'Ch. out of Service',
    Governorate: 'Governorate',
    Location: 'Location',
    Chargers: 'Chargers',
    Available: 'Available',
    Address: 'Address',
    Directions: 'Directions',
    DirectionsToStation: 'Directions To Station',
    StationsNearYou: 'Charging Stations Near You',
    Findtheneareststation: ' Find the nearest station to your current location',
    all: 'All',
    AddNewStation: 'Add New Station',
    StationName: 'Station Name',
    StationNameTranslation: 'Station Name Translation',
    PleaseInputStationName: 'Please enter station name ',
    stationNamePlaceHolder: 'Write the name of the station',
    stationNameTranslationPlaceHolder: 'Write station name translation',
    NumberOfChargers: 'Number Of Chargers',
    PleaseInputNumberOfChargers: 'Please enter Number Of Chargers',
    NumberOfChargersPlaceholder: 'set the number of available chargers',
    NumberOfOutOfServiceChargers: 'Number Of out Of Service Chargers',
    PleaseinputNumberOfoutOfService: 'Please enter Number Of out Of Service Chargers',
    outOfServiceChargersPlaceholder: 'set the number of out of service chargers',
    Governorate: 'Governorate',
    PleaseInputGovernorate: 'Please enter Governorate',
    stationGovernorate: 'station governorate',
    LocationOnMap: 'Location on Map',
    PleaseInputLocationonMap: 'Please enter Location on Map',
    LocationPlaceholder: 'pick the station on the map to set the location',
    chargerIdPlaceholder: 'Insert charger id',
    commentPlaceholder: 'Insert a comment',
    addCharger: '+ Add Charger',
    chargerID: 'Charger ID',
    chargerStatus: 'Status',
    chargerComment: 'Comment',
    typeanycomment: 'type any comment you want here...',
    Tiles: 'Tiles',
    AddChargerDetails: 'Add Charger Details',
    AddCharger: 'Add Charger',
    AddChargerSuccessfully: 'charger details added successfully',
    ID: 'Everon ID',
    Secretkey: 'Secret key',
    IPAddress: 'IP Address',
    Connectors: 'Connectors',
    Status: 'Status',
    StationSerialN: 'Station Serial N.',
    Stationmodel: 'Station model',
    StationVendor: 'Station Vendor',
    OCPPversion: 'OCPP version',
    ICCID: 'ICCID',
    SIMcardN: 'SIM card N.',
    IMSI: 'IMSI',
    Internationalmobilesubscriberidentity: 'Identity',
    Typechargerinternational: 'Type charger international mobileSubscriber identity ..',
    TypechargeSIMcard: 'Type charge SIM card number..',
    TypechargerOCPPversion: 'Type charger OCPP version..',
    TypechargerStationVendor: 'Type charger Station Vendor..',
    TypechargerStationmodel: 'Type charger Station model..',
    TypechargerStationSerialnumber: 'Type charger Station Serial number..',
    Numberofconnectors: 'Number of connectors and the totally available types of connectors Appears ',
    TypechargerIPaddress: 'Type charger IP address..',
    Typechargersecretkey: 'Type charger secret key..',
    TypechargerId: 'Type charger Code..',
    Map: 'Map',
    MoreInfo: 'More Info',
    stationAddedSuccessfully: 'Station added successfully',
    stationEditSuccessfully: 'Station edited successfully',
    chargersNo: 'Chargers Num',
    outOfServiceChargersNo: 'Out of service',
    governateName: 'Governorate Name',
    alreadyExists: 'Charger already exists',
    StationInfo: 'Station Info',
    ChargersInfo: 'Chargers Info',
    Edit: 'Edit',
    Delete: 'Delete',
    Address: 'Address',
    UploadPhoto: 'Upload Photo',
    Youcantchangethisfield: "You can't change this field, it depends on thedata listed in chargers info department ",
    NumberOfChargers: 'Number Of Chargers',
    NumberOfoutDutyChargers: 'Number Of out-Duty Chargers',
    NearMe: 'Near me',
    StationsResult: 'Charging stations',
    ChargersEmptyState: 'Sorry, no Chargers to display',
    Refrance: 'Refrance',
    RefrancePlaceHolder: 'Type Refrance ..',
    Code: 'Code',
    Type: 'Type',
    Status: 'Status',
    Action: 'Action',
    ConnectorsEmpty: 'Sorry,No Connectors to display',
  },
  Customers: {
    CustomersName: 'Customers name',
    Email: 'Email',
    Phone: 'Phone',
    CardStatus: 'Card status',
    Lastcharge: ' Last charge',
    ViewProfile: 'View profile',
    ViewCard: 'View card',
    MobilePhone: 'Mobile phone',
    Age: 'Age',
    Gender: 'gender',
    CarType: 'Car type',
    BatteryRange: 'Battery range',
    carlicense: 'car license',
    PersonalInfo: 'Personal info',
    CarInfo: 'Car info',
    Comments: 'Comments',
    writeyourcommenthere: 'write your comment here',
    active: 'Active',
    inactive: 'Inactive',
    inProgress: 'In Progress',
    delivered: 'Delivered',
    Requested: 'Requested',
    needsAction: 'Needs action',
    inDelivery: 'In delivery',
    canceled: 'Canceled',
    RequestInfo: 'Request info',
    new: 'New',
    address: 'Address',
    deliveryAddress: 'Delivery address',
    male: 'Male',
    fmale: 'Female',
    customerBan: 'Customer ban',
    commentSent: 'Comment succefully sent',
    statusUpdated: 'Status succefully updated ',
    userBaned: 'User succefully banned',
    userActivated: 'User succefully activated',
    wallet: 'wallet',
    sessions: 'sessions',
    add: 'add',
    deduct: 'deduct',
    balance: 'balance',
    reasonLabel: 'reason Label',
    amount: 'amount',
    reasonPlaceHolder: 'enter reason ',
    amountPlaceHolderAdd: 'enter added amount',
    amountPlaceHolderDeduct: 'enter deducted amount',
    deductionCreator: 'deduction Creator',
    additionCreator: 'addition Creator',
    amountRequired: 'amount is Required',
    ammountMin: 'Minimum Amount is 1 EGP',
    reasonRequired: 'reason is Required',
    reasonWhiteSpace: 'Reason does not accept spaces only ',
    rejected: 'Rejected',
    cardID: 'Card ID',
    referenceName: 'Reference Name',
    cardInfo: 'Card Info',
    cardStatus: 'Card Status',
    edit: 'Edit',
    editCard: 'Edit Card',
    addCard: 'Add Card',
    add: 'Add',
    issueDate: 'Issue Date',
    cardIDRequired: 'Card ID is required',
    issueDateRequired: 'issue Date is Required',
    noMobile: 'No mobile provided',
  },
  PricingSettings: {
    Settings: 'Settings',
    MinimumCharge: 'Minimum charge',
    KW: 'KW',
    CurrentAC: 'Alternating current (AC)',
    CurrentDC: 'Direct current (DC)',
    ChargersPricingUpdated: 'Chargers pricing successfully updated',
  },
  infinty: {
    EVChargingpoints: 'Welcome to the fastest growing & most reliable electric vehicle charging network in Egypt',
    Morethan50Stations: 'More than 500 Infinity charging points to date',
    contactUsText: 'For more information about how we operate in Egypt please',
    Locateyourneareststation: 'Contact us',
    EnvironmentFriendlyDrivingPleasure: 'Environment-Friendly, Driving Pleasure',
    zero: 'Zero',
    emissionisourvision: 'Emission is our vision',
    Getyour: 'Now you can download our app “InfinityEV” to be able to:',
    // ChargingCard: 'InfinityEv App',
    ChargingCardArticle:
      'Locate the nearest charge and get directions,Find different charger types with live status,Charge your Electric Vehicle',
    GetYourCard: 'Get your card',
    Stations: 'Charging stations',
    EVchargingstationstodate: 'Infinity stations to date',
    chargingPoints: 'Charging points',
    charginglocations: 'Charging points',
    charginglocationsby2021: 'Across Egypt by 2024',
    Neareststation: 'Stations near you',
    Setlocation: 'Set location',
    typeyourlocation: 'type your location here…',
    LocatetheNearestStation: 'Locate the nearest Infinity charging station',
    Findtheneareststation: 'Find the nearest station',
    // Stations: 'Stations',
    headerFooter: {
      Home: 'Home',
      AboutUs: 'About us',
      MobileApp: 'Mobile App',
      new: 'New',
      Howitworks: 'How it works',
      help: 'Help',
      Stations: 'Stations',
      ChargingStations: 'Charging stations',
      RequestCard: 'EV charging card',
      ContactUs: 'Contact us',
      About: 'About ',
      terms: 'Terms and conditions',
      Additionallinks: 'Additional links ',
      infintyAdress: 'F9, The Lane, 26th of July Corridor, 6 October City',
      SignIn: 'Sign in ',
      viewProfile: 'view profile',
      Notifications: 'Notifications',
      AllRightsReserved: 'All rights reserved',
    },
  },
  contactusRequests: {
    requestStatus: 'Request status',
    status: 'Request status',
    requestDetails: 'Request details',
    new: 'New',
    closed: 'Closed',
    close: 'Close',
    needsAction: 'Needs action',
    reply: 'Reply',
    replyHint: 'This reply will be sent directly to customer email',
    commentHint: 'This comment can only be seen by admins',
    replyPlaceholder: 'Write your request reply here',
    requestUpdatedSuccessfully: 'Request status successfully updated',
  },
  userProfile: {
    ChangePassword: 'Change password',
    NameMin: 'Name min 2 characters',
    NameMax: 'Name max is 12 characters',
    Lastname20Max: 'Last name cannot be longer than 20 characters',
    Firstname20Max: 'First name cannot be longer than 20 characters',
    emailMin: 'Email length must be more than 5 characaters',
    emailMax: 'Email length must be less than 150 characaters',
    PleaseEntername: 'Please enter name',
    minmaxaddress: 'Address must be between 3 and 300 characters',
    Name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    PleaseEnterValidmail: 'Please enter valid mail',
    PleaseEntermail: 'Please enter mail',
    mail: 'mail',
    Pleaseinputyourphonenumber: 'phonen number must be 11 num',
    PleaseEnteraddress: 'Please enter address',
    PleaseSelectGender: 'Please select Gender',
    Male: 'Male',
    Female: 'Female',
    ChangePassword: 'Change Password',
    PersonalInfo: 'Personal info',
    ChangeYourPassword: 'Change your Password',
    Uploadnewpicture: 'Upload new picture',
    address: 'address',
    Currentpassword: 'Current password',
    Newpassword: 'New password',
    Confirmnewpassword: 'Confirm new password',
    CarInfo: 'Electric car details',
    CarsInfo: ' Cars info',
    ArrivalAddress: 'Delivery address',
    sameashomeaddress: 'Same as home address',
    oneofourcustomers: 'If you already have an Infinity account, Please sign in first',
    AddmoreCar: 'Add more Car',
    selectUserCar: 'Please select a car',
    Selectcarbrand: 'Please select car brand',
    Selectcartype: 'Please select car type',
    PleaseEntercarbrand: 'Please enter car brand',
    cartype: 'car type',
    PleaseEntercartype: 'Please enter car type',
    PleaseEnterbetteryrange: 'Please enter battery range',
    Selectabetteryrange: 'Select a battery range',
    Attachcarlicense: 'Attach car license',
    copyofthecarlicense: 'A copy of the car license',
    oryoucanattachcustomsreleasecertificate: 'or you can attach customs release certificate',
    Requests: 'Card requests',
    NoRequests: 'No requests',
    carInfoAdded: 'Car info added successfully',
    carlicenseNeed: 'Please add car license no less than 2 images',
    youwanttocancel: 'Are you sure you want to cancel this request?',
    fillTheForm: 'Please fill out the form below to order your Infinity charging card:',
    carsUpdated: 'Car info updated successfully',
    deleteCar: 'Delete car',
    carDeleteConfirmation: 'Are you sure you want to delete this car ?',
    Createddate: 'Created date',
    Status: 'Status',
    DeliveryAddress: 'Delivery address',
    Action: 'Action',
    Welcome: 'Welcome',
    Sessions: 'Sessions',
    foraneasierexperience: 'For an easier experience make your account better by complete the following data.',
    Continue: 'Continue',
    Skipfornow: 'Skip for now',
    noLicenceSelected: 'Please select licence images',
    licenseFront: 'Please select license front image',
    licenseBack: 'Please select license back image',
    Wallet: 'Wallet',
    EmailExists: 'This email already exists, if this is your account, Please login',
    EmailExists: 'This email already exists, if this is your account, please login',
    Wallet: 'Wallet',
    TotalBalance: 'Total Balance ',
    Chargeyourwallet: 'Charge your wallet ',
    TransactionsHistory: 'Transactions History',
    All: 'All',
    Incoming: 'Incoming',
    Outcoming: 'Outcoming',
    CreditCard: 'Credit Card ',
    ThisamounthasbeentransferredviaYour: 'This amount has been transferred via Your',
    whichendupwith: 'which end up with',
    ThiscredithasbeenusedforaSession: 'This credit has been used for a Session.',
    Session: 'Session',
    SessionDetails: 'Session Details',
    Ok: 'Ok',
    pleaseEntername: 'please Enter name',
    Booking: 'Booking',
    BookCharger: ' BookCharger',
    Bookinglist: ' Bookinglist',
    CurrentBooking: ' Current Booking',
    CarBrandType: '  Car Brand Type:',
    ChargingType: ' Charging Type :',
    Time: 'Time :',
    Duration: 'Duration  :',
    StationLocation: 'Station Location  :',
    theremainingtime: 'the remaining time  :',
    CancelBooking: 'Cancel Booking ',
    BookNow: ' Book Now',
    NoBookingsYet: 'No Bookings Yet',
    Makefirstbooking: 'Make first booking',
    AreyousureyouwanttoCancelyourbooking: 'Are you sure you want t oCancel your booking',
    Rememberthatthecostofthetimebeforecancellationwillbededucted:
      'Remember that the cost of the time before cancel lation will be deducted',
    NoKeepBooking: 'No, Keep Booking',
    YesCancelBooking: 'Yes,Cancel Booking',
    BookedCanceledSuccessfully: 'Booked Canceled Successfully',
    Nobookingtoday: 'Nobooking today',
    Bookinghistory: 'Booking history',
    insufficientbalance: 'You have insufficient balance , PLease recharge your account to be able to use infinity Chargers',
    payNow: 'Pay Now',
    addCard: 'Add Card',
  },
  contactUs: {
    fullName: 'Full name',
    email: 'E-mail',
    RequestDetails: 'Message',
    title: 'Title',
    typePlaceholder: 'Select your message type',
    inquery: 'Inquiry',
    suggestion: 'Suggestion',
    praise: 'Praise',
    complaint: 'Complaint',
    PleaseinputyourRequestDetails: 'Please enter your request details',
    HereToHelp: 'We’re here to help',
    ContactUS: 'Contact us',
    ContactDetails: 'Contact details',
    OpeningHours: 'Opening hours',
    Closed: 'Closed',
    About: 'About',
    Howitworks: 'How it works',
    ChargingStations: 'Charging stations',
    RequestCard: 'EV charging card',
    Additionallinks: 'Additional links',
    AboutUs: 'About us',
    subjectMin: 'Subject length must be at least 20 characters',
    subjectMax: 'Subject maximum length must be 200 characters',
    nameMin: 'Name must be at least 2 characters',
    nameMax: "Name can't be more than 60 characters",
    dayopen: 'Sunday - Thursday',
    dayclose: 'Friday - Saturday',
    callCenterTitle: `24/7 CUSTOMER HOTLINE`,
    callCenterSubtitle: `Need to know more about charging at Infinity, EV charging, our charging points, pricing, payments or billing?
    Call us on our 24/7 hotline and we’ll be happy to assist you.`,
    surveyTitle: `Own a Prime Spot for an EV Charging Station? Share It with Us!`,
    surveySubtitle: `Are you a property owner with an ideal location for an EV charging station? By partnering with us, you can
    both improve your property's value and contribute to a greener future. Click the button below and fill out
    the survey to get started.`,
    surveyButton: 'Start',
  },
  notifications: {
    loadMore: 'Load more notifications',
    notifications: 'Notifications',
    noNotifications: "It's all good, you don't have new notifications",
    today: "Today's",
  },
  aboutUs: {
    aboutUsPragraph:
      'Infinity was established in 2018 with the vision to develop Egypt’s electric vehicle charging network infrastructure. We are working towards creating the most reliable and convenient EV charging network in Egypt. Infinity EV is a subsidiary of Infinity, the leading company in the renewable energy sector in Egypt .',
    Exploremore: 'Explore more',
    Ourstations: 'Our stations',
    OurstationsPragraph1:
      'All Infinity charging stations are equipped to charge at least 2 electric vehicles simultaneously, while some stations allow charging up to 6 vehicles. Stations are equipped with double-socket 22KW-AC-chargers and fast-charging DC chargers in major stations, providing an output of 50 KW to charge your electric car up to 125km in 30 minutes.',
    OurstationsPragraph2:
      'All stations confine to EU standards and cater for Type 2 charger cables. Alternatively, one can use an adapter cable to connect to the charging station.',
    Our: 'Our',
    Partners: 'Find us at',
    EVBox:
      'In collaboration with the globally renowned EVBox, Europe’s leading electric vehicle charging manufacturer and provider, Infinity is building the fastest-growing EV network in Egypt. We cooperate with strategic partners in several industries to ensure the best charging infrastructure for residents, businesses and cities.',
  },
  howItWork: {
    How: 'How',
    itworks: 'it works',
    Start: 'Connect',
    StartStep: 'Connect charger cable with car & station.',
    Second: 'Start',
    SecondStep: 'Start charging session by scanning your phone.',
    Third: 'Charge',
    ThirdStep: 'Blue led light indicates start of charging session.',
    End: 'Charging Complete',
    EndStep: 'Stop charging session by using your phone',
    RequestCard: 'Charging Card',
    Requestcharging: 'Order a card to be able to charge your car at any Infinity charging station',
    Search: 'Search',
    Searchlocation: 'Find your nearest Infinity charging station here',
    termsHeaderTitle: 'Terms & Conditions',
    termsHeaderSubtitle: 'Please read our Privacy Policy and our Terms of Service.',
  },
  signIn: {
    WelcomeBack: 'Welcome back',
    Tokeepconnected: 'To keep connected with us Please login with your personal info',
    SignIn: 'Sign in',
    SignUp: 'Sign up',
    HelloFriend: 'Welcome',
    personaldetails: 'Enter your personal details and start journey with us',
    SignintoInfinity: 'Sign in to Infinity',
    Forgotpassword: ' Forgot your password ?',
    haveaccount: ' Don’t have an account?',
  },
  sessions: {
    Sessions: 'Sessions',
    BookCharger: 'Book Charger',
    CarBrandType: 'Car Brand / Type ',
    TimeDuration: 'Time / Duration',
    ChargingType: 'Charging Type',
    KiloWatt: 'KiloWatt',
    Pickstationonmap: 'Pick station on map to set its location here..',
    PleasePickstationonmap: 'Please, select a station or set location on the map to find the nearest stations for it',
    StationLocation: 'Station Location',
    SelectConnectortype: 'Select Connector type',
    PleaseSelectConnectortype: 'Please Select Connector type',
    SelecttimeHHMM: 'Select time : HH:MM',
    PleaseSelecttimeHHMM: 'Please, select booking time',
    Selectcarbrand: 'Select your car brand / type',
    PleaseSelectcarbrand: 'Please, select your car',
    findstationslocation: 'find stations location ',
    chargerSerialnumber: 'Type charger Station Serial number..',
    ConnectorType: 'Connector Type',
    ConnectorStatus: 'Connector Status',
    Pick: 'Pick',
    Available: 'Available',
    Unavailable: 'Unavailable',
    FindStationsLocation: 'Find Stations Location ',
  },
  paymentModalLocale: {
    Payment: 'Payment',
    PaymentMessage: 'Add The Amount You Want To Charge Your Wallet',
    Amount: 'Amount',
    Confirm: 'Confirm',
    ValidAmount: 'Please Enter Valid Amount',
    MinAmount: 'Minimum Amount is 1 EGP',
    AmountRequired: 'Amount Feild is Required',
    Ok: 'Ok',
    PaymentCompleted: 'Payment process has been completed successfully',
    PaymentCanceled: 'Payment process has been Canceled',
    PaymentError: 'Error occurred in your payment process',
    YouAdded: 'You Added',
    YouHave: 'You Have',
    Egp: 'EGP',
  },
  uploader: {
    sizeExceeded: 'Maximum image size is 5 MB',
  },
};
